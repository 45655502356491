import { bannerTypes, bannerSizes } from '@/constant/bannerOptions'
import moment from 'moment'
import bannerApi from '@/api/common/bannerApi'
export default {
    props: {
        dataObject: {
            type: Object,
            default: () => null
        }
    },
    watch: {
        dataObject: {
            handler(newVal) {
                console.log('dataObject', newVal)
                this.itemEditForm = newVal.itemEdit
                this.isModified = newVal.isModified
            },
            deep: true
        }
    },
    data() {
        return {
            isLoading: false,
            bannerTypes,
            bannerSizes,
            isModified: false,
            itemEditForm: null,
            rules: {
                name: [
                    { required: true, message: 'Please input banner name', trigger: 'blur' },
                    { min: 3, message: 'Length should be more 3 characters', trigger: 'blur' }
                ],
                description: [
                    { required: true, message: 'Please input banner description', trigger: 'blur' },
                    { min: 3, message: 'Length should be more 3 characters', trigger: 'blur' }
                ],
                type: [{ required: true, message: 'Please select Banner type', trigger: 'change' }],
                size: [{ required: true, message: 'Please select Banner size', trigger: 'change' }],
                platform: [{ required: true, message: 'Please select Banner platform', trigger: 'change' }],
                imageUrl: [{ required: true, message: 'Please select Banner image', trigger: 'change' }]
            }
        }
    },
    mounted() {
        this.openEditView()
    },
    methods: {
        openEditView() {
            this.itemEditForm = { ...this.dataObject.itemEdit, enabled: this.dataObject.itemEdit.status === 1 }
            this.isModified = this.dataObject.isModified
            if (!this.isModified) {
                this.$set(this.itemEditForm, 'platform', 3)
                this.$set(this.itemEditForm, 'enabled', false)
                this.$set(this.itemEditForm, 'type', bannerTypes[0])
                this.$set(this.itemEditForm, 'size', bannerSizes[0])
                this.$set(this.itemEditForm, 'name', `New banner promotion`)
                this.$set(this.itemEditForm, 'description', `This is a new banner promotion`)
                this.$set(this.itemEditForm, 'link', `/recharge`)
                let currentUtc = moment().add(-7, 'hours').format('YYYY-MM-DD HH:mm:ss')
                let endOfMonthUtc = moment().endOf('month').add(-7, 'hours').format('YYYY-MM-DD HH:mm:ss')
                this.$set(this.itemEditForm, 'startTime', new Date(currentUtc))
                this.$set(this.itemEditForm, 'endTime', new Date(endOfMonthUtc))
            }
        },
        getFileExt(filename) {
            return filename.split('.').pop()
        },
        getImageMeta(url, cb) {
            const img = new Image()
            img.onload = () => cb(null, img)
            img.onerror = (err) => cb(err)
            img.src = url
        },
        openFileBrowser() {
            window.selectFileWithCKFinder((fileUrl) => {
                let targetUrl = fileUrl
                if (fileUrl.startsWith('http')) {
                    if (process.env.NODE_ENV === 'development') {
                        targetUrl = fileUrl.replace('https://cdn.myrender.vn', 'http://localhost:6871')
                    } else targetUrl = fileUrl.replace('https://cdn.myrender.vn', 'https://media-assets.gpuhub.net')
                } else {
                    if (process.env.NODE_ENV === 'development') targetUrl = `http://localhost:6871${fileUrl}`
                    else targetUrl = `https://media-assets.gpuhub.net${fileUrl}`
                }
                this.$set(this.itemEditForm, 'imageUrl', targetUrl)
                this.getImageMeta(targetUrl, (err, img) => {
                    if (img) this.$set(this.itemEditForm, 'size', `${img.naturalWidth}x${img.naturalHeight}`)
                })
            })
        },
        saveBanner() {
            this.$refs['itemEditForm'].validate((valid) => {
                if (valid) {
                    this.$confirm(`Save banner ${this.itemEditForm.name}. Continue?`, 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    })
                        .then(() => {
                            this.isLoading = true
                            bannerApi
                                .saveBanner({
                                    id: this.itemEditForm.id,
                                    platform: this.itemEditForm.platform,
                                    imageUrl: this.itemEditForm.imageUrl,
                                    size: this.itemEditForm.size,
                                    description: this.itemEditForm.description,
                                    name: this.itemEditForm.name,
                                    type: this.itemEditForm.type,
                                    link: this.itemEditForm.link,
                                    startTime: this.itemEditForm.startTime,
                                    endTime: this.itemEditForm.endTime,
                                    status: this.itemEditForm.enabled ? 1 : 0
                                })
                                .then((res) => {
                                    console.log('res', res)
                                    try {
                                        if (res.data.result === 0) {
                                            this.$message({
                                                message: `Congrats, save banner ${this.itemEditForm.name} success.`,
                                                type: 'success'
                                            })
                                            this.$emit('closeEditing')
                                        }
                                    } catch (error) {
                                        console.log('saveBanner -> error', error)
                                    }
                                    this.isLoading = false
                                })
                                .catch((error) => {
                                    console.log('saveBanner -> error', error)
                                    this.isLoading = false
                                })
                        })
                        .catch(() => {})
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
